<template>
  <div>
    <div class="app-top">
      <swiper-list :dataList="swiperList" :height="500" :interval="4000"></swiper-list>
    </div>
    <soft-list :btnDownload="true" :btnBuy="true" />
    <comm-faq />
    <comm-desc />
  </div>
</template>

<script>
import swiperList from '@/components/swiperList'
// import moduleTitle from '@/components/moduleTitle'
// import commService from '@/components/commService'
// import commSay from '@/components/commSay'
import commDesc from '@/components/commDesc'
import commFaq from '@/components/commFaq'
import softList from '@/components/softList'

export default {
  name: 'homeIndex',
  components: {
    swiperList,
    // moduleTitle,
    // commService,
    // commSay,
    commDesc,
    commFaq,
    softList
  },
  data() {
    return {
      dataList: [],
      swiperList: [
        {
          image: '',
          title: '购买正版',
          subTitle: '优秀的技术支持和售后服务、永久免费的版本升级',
          backgroundColor: 'linear-gradient(rgba(100,100,100,0.5), rgba(100,100,100,0.5))',
          backgroundImage: require('@/assets/images/bg/bg-1.jpg')
        }
      ]
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  created() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
@media screen and (max-width: 576px) {
}

.app-top {
  margin-top: 58px;
}
</style>