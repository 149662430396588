<template>
  <div>
    <div class="title">软件购买常见问题解答</div>
    <div class="container">
      <div class="row">
        <div class="col-sm-3" v-for="(item,index) in dataList" :key="index">
          <div class="box">
            <h4>{{item.title}}</h4>
            <p>{{item.text}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'commFaq',
  data() {
    return {
      dataList: [
        {
          title: '非工作时间可以购买吗？',
          text: '可以购买。系统自动发送注册码或开通网络版账户；加密狗版和U盘版支付成功后24小时内安排发货。'
        },
        {
          title: '购买后可以退款吗？',
          text: '除单机电脑版外，其他版本都支持30天内退换货服务！单机电脑版为虚拟注册码，不支持退款。可以先免费试用满意后再购买。'
        },
        {
          title: '软件升级收费吗？',
          text: '升级不收费！我们的软件永久免费升级。'
        },
        {
          title: '可以多人同时登陆和设置权限吗？',
          text: '可以。网络版可以购买多用户，各用户可以同时登录，以及设置不同权限。'
        }
      ]
    }
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {}
}
</script>
<style lang="scss" scoped>
p {
  margin-bottom: 0;
}
@media screen and (max-width: 576px) {
  .title {
    padding: 10px 0 !important;
  }
  .box {
    margin: 15px 0 !important;
    p {
      line-height: 20px !important;
    }
  }
}
.title {
  padding: 15px 0;
  color: white;
  background: #00aa40;
  font-size: 20px;
  text-align: center;
  .box {
    margin: 30px 0;
    h4 {
      font-size: 18px;
    }
    p {
      font-size: 14px;
      line-height: 26px;
      color: #666;
    }
  }
}
.box {
  margin: 30px 0;
  h4 {
    font-size: 18px;
  }
  p {
    font-size: 14px;
    line-height: 26px;
    color: #666;
  }
}
</style>